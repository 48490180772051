body {
  background-color: rgba(0, 0, 0, 0.02);
}

.grid {
  background-image: linear-gradient(to bottom, rgba(0, 170, 255, 0.3) 1px, transparent 1px);
  background-repeat: repeat;
  background-position: left top;
  background-size: 100% 18px;
  line-height: 36px;
  font-size: 24px;
}

.grid-hide {
  background-image: none;
}

.grid h1 {
  line-height: 72px;
  margin-top: 108px;
  font-size: 56px;
  font-family: Futura;
  font-weight: 600;
  letter-spacing: -0.05em;
}

.grid h2 {
  line-height: 54px;
  font-size: 36px;
  font-family: Futura;
  font-weight: 600;
  margin-bottom: 36px;
}

.grid h3 {
  line-height: 54px;
  font-size: 32px;
  font-family: Futura;
  font-weight: 600;
  margin-bottom: 36px;
}

.grid h4 {
  line-height: 36px;
  font-size: 24px;
  font-family: Futura;
  font-weight: 600;
  margin-bottom: 18px;
}

.grid a {
  font-weight: bold;
  text-decoration: underline;
}

.grid p, .grid pre, .grid h1, .grid blockquote, .grid ul, .grid ol {
  margin-bottom: 36px;
}

.grid pre, .grid pre code {
  color: #CCC;
  background-color: #222;
  padding: 9px;
  font-size: 0.75em;
  line-height: 27px;
}

.grid code {
  font-size: 0.9em;
  background-color: #eee;
  padding: 0 2px;
  border-radius: 5px;
}

.grid ul {
  list-style-type: disc;
  list-style-position: outside;
}

.grid ol {
  list-style-type: decimal;
  list-style-position: outside;
}

.grid blockquote {
  color: #888;
  padding-left: 18px;
  border-left: 9px solid #ccc;
  margin-left: -27px;
}

button {
  background-color: white;
  border: 0;
  padding: 0.8em;
  font-size: 16px;
}

button.active {
  background-color: #ccc;
}

button:hover {
  background-color: #EEE;
  color: #222;
}

.editor {
  margin: auto;
}

.editor-toolbar {
  background-color: white;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #EEE;
  position: sticky;
  top: 0px;
  margin-bottom: 2em;
}

.editor-panel img {
  width: 100%;
}

.editor-panel .image-selected {
  box-shadow: 0 0 0 3px #B4D5FF;
}

.editor-toolbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.App {
  margin: auto;
  margin-top: 80px;
}

.debug-panel {
  position: absolute;
  right: 0;
  width: 650px;
  overflow: auto;
}

span.highlight {
  background-color: yellow;
}
